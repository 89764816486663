import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { getBanks, getCurrencies } from "../services/dictionariesService";
import { TResponseStatus } from "../types/responseStatus";
import { ResponseStatus } from "../constants/responseStatus";

export interface DictionariesState {
  dictionariesStatus: TResponseStatus;
  banks: null | string[];
  currencies: null | any[];
}

const initialState: DictionariesState = {
  dictionariesStatus: ResponseStatus.IDLE,
  banks: null,
  currencies: null,
};

export const getDictionariesAsync = createAsyncThunk(
  "dict/getDictionariesAsync",
  async () => {
    const banks = await getBanks();
    const currencies = await getCurrencies();

    return { banks, currencies };
  }
);

export const dictionariesSlice = createSlice({
  name: "dictionaries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDictionariesAsync.pending, (state) => {
        state.dictionariesStatus = ResponseStatus.LOADING;
      })
      .addCase(getDictionariesAsync.fulfilled, (state, action) => {
        state.dictionariesStatus = ResponseStatus.IDLE;
        state.banks = action.payload.banks;
        state.currencies = action.payload.currencies;
      })
      .addCase(getDictionariesAsync.rejected, (state) => {
        state.dictionariesStatus = ResponseStatus.FAILED;
      });
  },
});

export const dictionariesSelectors = {
    banks: (state: RootState) => state.dictionaries.banks,
    currencies: (state: RootState) => state.dictionaries.currencies,
};

export default dictionariesSlice.reducer;
