/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

type Props = {
  rates: any;
  className: string;
  innerPadding?: string;
};

const RatesTable: React.FC<Props> = ({
  className,
  innerPadding = "",
  rates,
}) => {
  return (
    <div className="card-body pt-3 pb-0 mt-n3">
      <div className="tab-content mt-4" id="myTabTables2">
        {/* begin::Tap pane */}
        <div
          id="kt_tab_pane_2_1"
          role="tabpanel"
          aria-labelledby="kt_tab_pane_2_1"
          className="tab-pane fade active show"
        >
          {/* begin::Table */}
          <div className="table-responsive">
            <table
              className="table align-middle border-gray-100"
              id="kt_advance_table_widget_4"
            >
              <thead>
                <tr className="text-center text-muted fw-bolder text-gray-400 text-uppercase fs-7 border-gray-100 border-bottom-1">
                  <td className="min-w-100px ">Conversion</td>
                  <td className="min-w-100px ">Rate</td>
                  <td className="min-w-100px ">Daily diff</td>
                  <td className="min-w-100px ">Daily diff %</td>
                </tr>
              </thead>
              <tbody>
                {rates?.map(({ from, to, value, diff, diffPerc }, id) => {
                  const diffColorClass = diff < 0 ? "text-danger" : "text-primary";

                  return (
                    <tr key={id}>
                      <td>
                        <span className="text-center text-gray-800 fw-bolder d-block fs-6">
                          {from}/{to}
                        </span>
                      </td>
                      <td>
                        <span className="text-center text-gray-800 fw-bolder d-block fs-6">
                          {value.toFixed(4)}
                        </span>
                      </td>
                      <td>
                        <span className={`text-center  fw-bolder d-block fs-6 ${diffColorClass}`}>
                          {diff}
                        </span>
                      </td>
                      <td>
                        <span className={`text-center  fw-bolder d-block fs-6 ${diffColorClass}`}>
                          {diffPerc}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Tap pane */}
      </div>
    </div>
  );
};

export { RatesTable };
