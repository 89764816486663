/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { getRates } from "../../services/ratesService";
import { useAppSelector } from "../hooks/useAppSelector";
import { dictionariesSelectors } from "../../slices/dictionariesSlice";

const calculateSum = (sum, rate) => {
  if (rate) {
    return (sum * rate).toFixed(4);
  }
  return "";
};

export function CurrencyConverter({ banks }) {
  const currencies = useAppSelector(dictionariesSelectors.currencies);

  const currentDate = dayjs(new Date()).format("YYYY-MM-DD");

  const [fromCurrency, setFromCurrency] = useState<string>("USD");
  const [toCurrency, setToCurrency] = useState<string>("GEL");
  const [source, setSource] = useState<string>("nbg");
  const [fromSum, setFromSum] = useState<number | string>("");
  const [toSum, setToSum] = useState<number | string>("");
  const [date, setDate] = useState<string>(currentDate);
  const [rate, setRate] = useState<number | string>(1);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setError(false);
    getRates({
      bank: [source],
      from: fromCurrency,
      to: toCurrency,
      start_date: date,
      end_date: date,
    }).then((data) => {
      if (!data?.values.length) {
        setFromSum("");
        setToSum("");
        setRate("");
        setError(true);
        return;
      }
      const newRate = data?.values[0]?.value;
      setRate(newRate);
      if (fromSum && fromSum !== 0) {
        setToSum(calculateSum(fromSum, newRate));
      }
    });
  }, [source, fromCurrency, toCurrency, date]);

  return (
    <div className="py-4 px-7 px-lg-12">
      <form className="form" method="post">
        <div className="mt-5">
          <div className="fw-bolder text-dark fs-3 mb-4">
            Currency converter
          </div>
          <div className="mb-8">
            <label className="fw-bolder">From</label>
            <div className="d-flex input-group">
              <select
                className="form-select form-select-solid form-select-lg"
                value={fromCurrency}
                onChange={(e) => setFromCurrency(e.target.value)}
              >
                {currencies?.map((cur) => (
                  <option key={cur.short} value={cur.short}>
                    {cur.short}
                  </option>
                ))}
              </select>

              <input
                type="number"
                min="0"
                className="form-control form-control-solid form-control-lg w-50"
                placeholder="From"
                value={fromSum}
                onChange={(e) => {
                  if (e.target.value) {
                    setFromSum(Number(e.target.value));
                    setToSum(calculateSum(Number(e.target.value), rate));
                  } else {
                    setFromSum("");
                    setToSum("");
                  }
                }}
              />
            </div>
          </div>
          <div className="mb-8">
            <label className="fw-bolder">To</label>
            <div className="d-flex input-group">
              <select
                className="form-select form-select-solid form-select-lg"
                value={toCurrency}
                onChange={(e) => setToCurrency(e.target.value)}
              >
                {currencies?.map((cur) => (
                  <option key={cur.short} value={cur.short}>
                    {cur.short}
                  </option>
                ))}
              </select>

              <input
                type="number"
                min="0"
                className="form-control form-control-solid form-control-lg w-50"
                placeholder="To"
                value={toSum}
                // onChange={(e) => setToSum(e.target.value)}
                disabled
              />
            </div>
          </div>
          <div className="mb-8">
            <label className="fw-bolder">Source</label>
            <select
              className="form-select form-select-solid form-select-lg"
              value={source}
              onChange={(e) => setSource(e.target.value)}
            >
              {banks?.map((bank) =>
                <option key={bank.short} value={bank.short}>{bank.long}</option>
              )}
            </select>
          </div>
          <div className="mb-8">
            <label className="fw-bolder">Date</label>
            <input
              type="date"
              className="form-control form-control-solid form-control-lg"
              placeholder="Date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
        </div>
        {error && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              No information about rate
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
