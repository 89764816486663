import axios from "axios";
import { BASE_URL } from "../constants/env";

export const getBanks = () =>
  axios.get(BASE_URL + `api/v1/banks`).then((response) => {
    if (response.status === 200) {
      return response.data?.values;
    }
    return null;
  });

export const getCurrencies = () =>
  axios.get(BASE_URL + `api/v1/currencies`).then((response) => {
    if (response.status === 200) {
      return response.data?.values;
    }
    return null;
  });
