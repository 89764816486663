import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { getRates } from "../services/ratesService";
import { TResponseStatus } from "../types/responseStatus";
import { ResponseStatus } from "../constants/responseStatus";
import { prepareRates } from "../utils/rates";
import { getToday, getYesterday } from "../utils/dayjs";

export interface RatesState {
  ratesStatus: TResponseStatus;
  rates: null | any;
  pageCount: number;
}

const initialState: RatesState = {
  ratesStatus: ResponseStatus.IDLE,
  rates: null,
  pageCount: 1,
};

export const getRatesAsync = createAsyncThunk(
  "rates/getRatesAsync",
  async (params) => {
    const rates = await getRates(params);
    const preparedRates = prepareRates(
      rates?.values,
      getToday(),
      getYesterday()
    );

    return { rates: preparedRates, pageCount: rates.page_count };
  }
);

export const ratesSlice = createSlice({
  name: "rates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRatesAsync.pending, (state) => {
        state.ratesStatus = ResponseStatus.LOADING;
      })
      .addCase(getRatesAsync.fulfilled, (state, action) => {
        state.ratesStatus = ResponseStatus.IDLE;
        state.rates = action.payload.rates;
        state.pageCount = action.payload.pageCount;
      })
      .addCase(getRatesAsync.rejected, (state) => {
        state.ratesStatus = ResponseStatus.FAILED;
      });
  },
});

export const ratesSelectors = {
  ratesStatus: (state: RootState) => state.rates.ratesStatus,
  rates: (state: RootState) => state.rates.rates,
  pageCount: (state: RootState) => state.rates.pageCount,
};

export default ratesSlice.reducer;
