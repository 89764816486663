import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RatesWrapper } from "../xratesComponents/RatesWrapper";
import { TermsAndConditionsPage } from "../xratesComponents/TermsAndConditionsPage";
import { PrivacyPolicyPage } from "../xratesComponents/PrivacyPolicyPage";
import { ApiDocsPage } from "../xratesComponents/ApiDocsPage";
import { DisclamerPage } from "../xratesComponents/DisclamerPage";
import { ErrorsPage } from "../modules/errors/ErrorsPage";

export function Routes() {
  return (
    <Switch>
      <Route path="/disclamer" component={DisclamerPage} />
      <Route path="/api" component={ApiDocsPage} />
      <Route path="/terms_and_conditions" component={TermsAndConditionsPage} />
      <Route path="/privacy_policy" component={PrivacyPolicyPage} />
      <Route exact path="/" component={RatesWrapper} />
      <Route path="*" component={ErrorsPage} />
    </Switch>
  );
}
