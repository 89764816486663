import React from "react";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import { MasterLayout } from "../../_start/layout/MasterLayout";

export const ApiDocsPage = () => (
  <MasterLayout>
    <SwaggerUI url="https://api.xrates.ge/swagger" />
  </MasterLayout>
);
