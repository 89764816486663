import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "../_start/layout/core";
import { MasterLayout } from "../_start/layout/MasterLayout";
import { Routes } from "./routing/Routes";


type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  return (
    <BrowserRouter basename={basename}>
      <ThemeProvider>
        <Switch>
          <Routes />
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export { App };
