import React from "react";
import { MasterLayout } from "../../_start/layout/MasterLayout";

export const DisclamerPage = () => {
    return (
      <>
        <MasterLayout>
          <h3>Data Listing and Disclaimers</h3>
          <ul>
            <li>A list of all exchanges and other financial data available in Xrates.ge</li>
            <li>Assotiated Disclamers</li>
          </ul>

          <h3>Exchange Rates</h3>
          <p>
            All exchange rates provided by banks directly via official.
            Exchange rate values is accurate up to the day.
            New day exchange rates values load with delay up to 5 min.
          </p>

          <h3>Exchange Rates Sources:</h3>
          <ul>
            <li>TBC Bank - <a href="https://developers.tbcbank.ge/reference/exchangerates-api-overview" target='_blank'>official API</a></li>
            <li>Bank of Georgia - <a href="https://developers.tbcbank.ge/reference/exchangerates-api-overview" target='_blank'>official API</a></li>
            <li>National Bank of Georgia - <a href="https://api.bog.ge/docs/en/bonline/rates/commercial-sellCurrency-buyCurrency" target='_blank'>official API</a> (Cross Rates request with amount value set to 1)</li>
          </ul>

          {/* TODO: make list be loaded from API */}
          <h3>Available Currencies</h3>
          <table className='table table-striped table-sm table-bordered'>
            <thead className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <tr><th scope="col">Currency Code</th><th scope="col">Currency Name</th></tr>
            </thead>
            <tbody>
              <tr><td>GEL</td><td>Georgian Lari</td></tr>
              <tr><td>USD</td><td>US Dollar</td></tr>
              <tr><td>EUR</td><td>Euro</td></tr>
              <tr><td>GBP</td><td>British Pound</td></tr>
              <tr><td>AED</td><td>United Arab Emirates Dirham</td></tr>
              <tr><td>AMD</td><td>Armenian Dram</td></tr>
              <tr><td>AUD</td><td>Australian Dollar</td></tr>
              <tr><td>AZN</td><td>Azerbaijanian Manat</td></tr>
              <tr><td>BGN</td><td>Bulgarian Lev</td></tr>
              <tr><td>CAD</td><td>Canadian Dollar</td></tr>
              <tr><td>CHF</td><td>Swiss Franc</td></tr>
              <tr><td>CZK</td><td>Czech Koruna</td></tr>
              <tr><td>DKK</td><td>Danish Krone</td></tr>
              <tr><td>ILS</td><td>Israeli Shekel</td></tr>
              <tr><td>JPY</td><td>Japanese Yen</td></tr>
              <tr><td>NOK</td><td>Norwegian Krone</td></tr>
              <tr><td>PLN</td><td>Polish Zloty</td></tr>
              <tr><td>RUB</td><td>Russian Ruble</td></tr>
              <tr><td>SEK</td><td>Swedish Krona</td></tr>
              <tr><td>TRY</td><td>Turkish Lira</td></tr>
              <tr><td>UAH</td><td>Ukraine Hryvni</td></tr>
            </tbody>
          </table>



          <h3>Disclaimers</h3>
          <p>All data and information is provided “as is” for informational purposes only, and is not intended for trading purposes or financial, investment, tax, legal, accounting or other advice. Please consult your broker or financial representative to verify pricing before executing any trade. Xrates.ge is not an investment adviser, financial adviser or a securities broker. None of the data and information constitutes investment advice nor an offering, recommendation or solicitation by Xrates.ge to buy, sell or hold any security or financial product, and Xrates.ge makes no representation (and has no opinion) regarding the advisability or suitability of any investment.</p>

          <p>None of the data and information constitutes investment advice (whether general or customized). The financial products or operations referred to in such data and information may not be suitable for your investment profile and investment objectives or expectations. It is your responsibility to consider whether any financial product or operation is suitable for you based on your interests, investment objectives, investment horizon and risk appetite. Xrates.ge shall not be liable for any damages arising from any operations or investments in financial products referred to within. Xrates.ge does not recommend using the data and information provided as the only basis for making any investment decision.</p>

          <p>Data is provided by financial exchanges and other content providers and may be delayed as specified by financial exchanges or other data providers. Xrates.ge does not verify any data and disclaims any obligation to do so.</p>

          <p>Xrates.ge, its data or information providers (A) expressly disclaim the accuracy, adequacy, or completeness of any data and (B) shall not be liable for any errors, omissions or other defects in, delays or interruptions in such data, or for any actions taken in reliance thereon. Neither Xrates.ge nor any of our information providers will be liable for any damages relating to your use of the information provided herein. As used here, "information providers" does not refer to an agency, partnership, or joint venture relationship between Xrates.ge and any such parties.</p>

          <p>You agree not to copy, modify, reformat, download, store, reproduce, reprocess, transmit or redistribute any data or information found herein or use any such data or information in a commercial enterprise without obtaining prior written consent.</p>

          <p>Either Xrates.ge or its third party data or content providers have exclusive proprietary rights in the data and information provided.</p>

          <p>Please find all listed exchange rates covered by Xrates.ge along with their respective time delays from the written above.</p>

          <p>Advertisements presented on Xrates.ge are solely the responsibility of the party from whom the ad originates. Neither Xrates.ge nor any of its data licensors endorses or is responsible for the content of any advertisement or any goods or services offered therein.</p>

          <h3>Currency Conversion</h3>
          <p>Xrates.ge cannot guarantee the accuracy of the exchange rates displayed. You should confirm current rates before making any transactions that could be affected by changes in the exchange rates.</p>

          <h3>Search Ranking</h3>
          <p>Xrates.ge provides a simple way to search for exchange rates ('Financial Data'). The Financial Data is obtained from various data providers and feeds into a unified format available for serving to users. Xrates.ge ranks search suggestions on the basis of three main elements: Exact matches to queries, Xrates.ge Search impressions, and Xrates.ge impressions.</p>
        </MasterLayout>
      </>

    )
  }
