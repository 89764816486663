import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store";
// Apps
import { App } from "./app/App";
import "./_start/assets/sass/style.scss";
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

ReactDOM.render(
  <Provider store={store}>
    <App basename={PUBLIC_URL} />
  </Provider>,
  document.getElementById("root")
);
