export const prepareRates = (rawRates, today, yesterday) => {
    if (rawRates === undefined) return;
  
    // returns { "2022-21-11": { "usdeurtbc": rawRate, ... }, ... }
    const datedRates = rawRates.reduce((acc, rawRate) => {
      const { from, to, bank } = rawRate;
      const indexKey = `${from}${to}${bank}`;
      const accIndex = acc[rawRate.date] || {};
      const datedIndex = { ...accIndex, [indexKey]: rawRate };
      return { ...acc, [rawRate.date]: datedIndex };
    }, {});
  
    if (!(today in datedRates)) return;
  
    const yesterdays = datedRates[yesterday];
    const todays = datedRates[today];
  
    return Object.keys(todays).map((key) => {
      const rawRate = todays[key];
  
      const t = rawRate.value;
      const y = yesterdays?.[key]?.value || 0;
  
      return {
        ...rawRate,
        diff: (t - y).toFixed(4),
        diffPerc: (((t - y) / t) * 100).toFixed(2) + "%",
      };
    });
  };