import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import ratesReducer from "./slices/ratesSlice";
// import converterReducer from "./slices/converterSlice";
import dictionariesReducer from "./slices/dictionariesSlice";

export const store = configureStore({
  reducer: {
    rates: ratesReducer,
    // converter: converterReducer,
    dictionaries: dictionariesReducer,
  },
});

export async function dispatchFromService(action) {
  await store.dispatch(action);
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
