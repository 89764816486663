/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import queryString from "query-string";
import { RatesTable } from "./RatesTable";
import dayjs from "dayjs";
import Pagination from "react-js-pagination";
import { useHistory, useLocation } from "react-router-dom";
import { PER_PAGE } from "../../constants/rates";
import { CurrencyConverter } from "./CurrencyConverter";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { getRatesAsync, ratesSelectors } from "../../slices/ratesSlice";
import { getToday, getYesterday } from "../../utils/dayjs";
import { useAppSelector } from "../hooks/useAppSelector";
import { ResponseStatus } from "../../constants/responseStatus";
import { dictionariesSelectors, getDictionariesAsync } from "../../slices/dictionariesSlice";

const DEFAULT_PAGE = 1;
const initSearchParams = {
  bank: "nbg",
  page: DEFAULT_PAGE,
};

export const Rates: React.FC = () => {
  let history = useHistory();
  const dispatch = useAppDispatch();
  const ratesStatus = useAppSelector(ratesSelectors.ratesStatus);
  const pageCount = useAppSelector(ratesSelectors.pageCount);
  const rates = useAppSelector(ratesSelectors.rates);
  const banksDictionary = useAppSelector(dictionariesSelectors.banks);
  const { search } = useLocation();

  const searchParams = queryString.parse(search);

  const setSearchParamsToURL = (params) =>
    history.push({
      search: queryString.stringify(params),
    });
  const setBankParamToURL = (bankParam: string) =>
    setSearchParamsToURL({
      ...searchParams,
      bank: bankParam,
      page: DEFAULT_PAGE,
    });

  useEffect(() => {
    dispatch(getDictionariesAsync());
  }, []);

  useEffect(() => {
    if (!search) {
      setSearchParamsToURL({ ...initSearchParams, ...searchParams });
    }

    if (search) {
      const { bank, page, ...otherParams } = searchParams;
      if (!(bank && page)) return;

      dispatch(getRatesAsync({
          start_date: getYesterday(),
          end_date: getToday(),
          bank: [bank],
          page,
          ...otherParams,
        })
      );
    }
  }, [search, history]);

  const onPageButtonClick = (newPage) => {
    setSearchParamsToURL({
      ...searchParams,
      page: newPage,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-4">
          <div className={`card mb-10`}>
            <CurrencyConverter banks={banksDictionary} />
          </div>
        </div>

        <div className="col-xl-8">
          <div className={`card mb-10`}>
            <div className={`card-header border-0 pt-5`}>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark fs-3">
                  Today: {dayjs(new Date()).format("MMM D, YYYY")}
                </span>
              </h3>

              <div className="card-toolbar">
                <ul className="nav nav-pills nav-pills-sm nav-light">
                  {banksDictionary?.map((b) => {
                    const shortName = b.short;
                    return (
                      <li className="nav-item" key={shortName}>
                        <a
                          className={`nav-link btn btn-active-light btn-color-muted py-2 px-4 fw-bolder me-2 ${
                            searchParams.bank === shortName ? "active" : ""
                          }`}
                          data-bs-toggle="tab"
                          href="#kt_tab_pane_2_1"
                          onClick={() => setBankParamToURL(shortName)}
                        >
                          {b.long}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {/* end::Header */}
            <RatesTable
              className="card-stretch mt-15 mb-5 mb-xxl-8"
              rates={ratesStatus === ResponseStatus.IDLE ? rates : []}
            />
          </div>
          <Pagination
            activePage={Number(searchParams.page) || DEFAULT_PAGE}
            itemsCountPerPage={searchParams.per_page || PER_PAGE}
            totalItemsCount={pageCount * PER_PAGE}
            pageRangeDisplayed={5}
            onChange={onPageButtonClick}
            innerClass="pagination"
            itemClass="page-item"
            disabledClass="disabled"
            activeClass="active"
            linkClass="page-link"
            itemClassPrev="previous"
            itemClassNext="next"
            itemClassFirst="previous"
            itemClassLast="next"
          />
        </div>
      </div>
    </>
  );
};
