import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../helpers";
import { usePageData, useTheme } from "../../core";

export function Header() {
  const { config, classes, attributes } = useTheme();
  const { pageTitle, moduleName } = usePageData();
  return (
    <>
      <div
        className={`${classes.headerContainer.join(
          " "
        )} d-flex align-items-stretch justify-content-between`}
        {...Object.fromEntries(Array.from(attributes.headerContainer))}
      >
        {/* begin::Left */}
        <div className="d-flex align-items-center">
          {config.aside.content && config.aside.content === "menu" && (
            <h3 className="text-dark fw-bolder my-1 fs-2">{pageTitle}</h3>
          )}
          {config.aside.content && config.aside.content === "docs" && (
            <>
              {/* begin::Title */}
              <h3 className="d-flex align-items-center text-dark fw-bolder my01 fs-5 fs-lg-2">
                <span className="d-none d-lg-inline">{moduleName}</span>&nbsp;
                {pageTitle && (
                  <span className="badge badge-light-danger fw-bold fs-8 shadow-sm ms-2">
                    {process.env.REACT_APP_VERSION}
                  </span>
                )}
              </h3>
              {/* end::Title */}
            </>
          )}
          {!config.aside.content && (
            <>
              {/* begin::Logo */}

              <Link className="text-dark" to="/">
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo-compact.png")}
                    className="h-40px"
                  />
                  <h1 className=" fw-bolder mt-2 ms-4">Xrates.ge</h1>
                </div>
              </Link>
              {/* end::Logo */}
            </>
          )}
        </div>
        {/* end::Left */}
      </div>
    </>
  );
}
