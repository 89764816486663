import axios from "axios";
import { BASE_URL } from "../constants/env";
import { PER_PAGE } from "../constants/rates";

export const getRates = ({
  bank,
  from,
  to,
  start_date,
  end_date,
  page = 1,
  per_page = PER_PAGE,
}) =>
  axios
    .get(BASE_URL + `api/v1/rates`, {
      params: { bank, from, to, start_date, end_date, page, per_page, type: ['_none', 'liberty_commercial'] },
      paramsSerializer: params => {
        return Object
          .entries(params)
          .filter(([_key, value]) => value)
          .map(([key, value]) => {
            if(Array.isArray(value)) return value.map((v) => `${key}[]=${v}`).join('&');

            return new URLSearchParams(`${key}=${value}`).toString();
          })
          .join("&");
      }
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return null;
    });
