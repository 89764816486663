/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTheme } from "../core";
import { Link } from "react-router-dom";

export function Footer() {
  const { classes } = useTheme();
  return (
    <div className={`footer py-4 d-flex flex-lg-column`} id="kt_footer">
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold me-2">
            {new Date().getFullYear()} &copy;
          </span>
          <Link to="/" className="text-gray-800 text-hover-primary">XRates.ge</Link>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li className="menu-item">
            <Link to="/api" className="menu-link ps-0 pe-2">API</Link>
          </li>
          <li className="menu-item">
            <Link to="/terms_and_conditions" className="menu-link pe-2">Terms And Conditions</Link>
          </li>
          <li className="menu-item">
            <Link to="/privacy_policy" className="menu-link pe-2">Privacy Policy</Link>
          </li>
          <li className="menu-item">
            <Link to="/disclamer" className="menu-link pe-2">Disclamer</Link>
          </li>
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  );
}
